export const homeObjOne = {

    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'About Me',
    headLine: 'Solo Indie Game Developer',
    description: 'Goals of making fun games on PC, and Console. Currently working on my first published project - Barbarian.  ',
    buttonLabel: 'Published Games',
    imgStart: true,
    img: require("../../Images/BarbOutside.jpg").default,
    alt: 'Barbarian Gameplay Screenshot',
    dark: true,
    primary: true,
    darkText: false

};

export const homeObjTwo = {

    id: 'discover',
    lightBg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Indie Dev Logs',
    headLine: 'Check out the Development Progress Logs!',
    description: 'Feedback is greatly appreciated! Leave your comments, and thoughts, in the youtube comment section!',
    buttonLabel: 'Youtube Channel',
    imgStart: false,
    img: require("../../Images/svg-2.svg").default,
    alt: 'CraftingBenchGames Youtube Channel',
    dark: true,
    primary: true,
    darkText: false

};

export const homeObjThree = {

    id: 'shop',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Merchandise',
    headLine: 'Check out the awesome designs!',
    description: 'Enjoy our games, and want to support us further? You can do so by buying our awesome & high quality designed Merchandise.',
    buttonLabel: 'Our Merchandise',
    imgStart: false,
    img: require("../../Images/Tshirt1.png").default,
    alt: 'Our Merchandise',
    dark: true,
    primary: true,
    darkText: false

};
// import { logDOM } from '@testing-library/dom'
import React, {useState, useEffect} from 'react'
import {FaBars} from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'
import {animateScroll as scroll} from 'react-scroll'
import {Nav, NavBarContainer, NavLogo, MobileIcon, NavMenu, NavItem,NavLinks,NavBtn, NavRedirect, NavRedirectShop} from './NavbarElements'
import logo from '../../Images/logo_white.png';


const Navbar = ( {toggle} ) => {

    const[scrollNav, setScrollNav] = useState(false)

    const changeNav = ()=> {
        if(window.scrollY >= 80){
            setScrollNav(true)
        }else{
            setScrollNav(false)
        }
    }
    

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    },[])


    const toggleHome = () => {

        scroll.scrollToTop();


    };

    return (
        <>

        <IconContext.Provider value ={{color: '#fff'}}>
            <Nav scrollNav={scrollNav}>

            <NavBarContainer>
                
                <NavLogo to='/' onClick={toggleHome}> <img alt="CraftingBenchGames Logo" src={logo} /> </NavLogo>

                <MobileIcon onClick={toggle}>

                    <FaBars />

                </MobileIcon>

                <NavMenu>



                    <NavItem>

                    <NavLinks to="about"
                    
                    smooth={true} duration={500} spy={true} exact='true' offset = {-80}

                    > About </NavLinks>

                    </NavItem>

                    <NavItem>

                    <NavLinks to="discover"
                    
                    smooth={true} duration={500} spy={true} exact='true' offset = {-80}
                    
                    
                    > Dev Logs </NavLinks>

                    </NavItem>

                    <NavRedirectShop>  <a href="https://craftingbenchgames.shop/" target = "blank" > Shop</a> </NavRedirectShop>

      

                    <NavItem>


                    <NavRedirect>  <a href="mailto:contact@craftingbenchgames.com" target = "blank" > Contact</a> </NavRedirect>

                    
                      

                    {/* <NavLinks to="contactUs"
                    
                    smooth={true} duration={500} spy={true} exact='true' offset = {-80}
                    
                    > Contact us </NavLinks> */}

                    

                    </NavItem>

                    <NavItem>

                    {/* <NavLinks to="signup"
                    
                    smooth={true} duration={500} spy={true} exact='true' offset = {-80}
                    
                    
                    > Sign Up </NavLinks> */}

                    </NavItem>

                </NavMenu>

                <NavBtn>

                {/* <NavBtnLink to="/signin" onClick={toggle}>Sign In</NavBtnLink> */}

                </NavBtn>

        

            </NavBarContainer>
            

            </Nav>

            </IconContext.Provider>
        </>
    )
}

export default Navbar

import React from "react";
import PropTypes from "prop-types";
import '../Youtube/styles.css'


const YoutubeEmbed = ({ embedId }) => (



 

    <iframe


      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="10"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Barbarian Indie Dev Log"


    />


);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;
import React from 'react'
import {ShopBtn, WishListBtn} from '../ButtonElements';
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap,ImgWrap,Img,ShopContainer, TopLineShop } from './InfoElements'
import YoutubeEmbed from '../Youtube/YoutubeEmbed';
import { Button } from '../ButtonElements';



const InfoSection = (
    
    {lightBg,
        id, 
        imgStart, 
        topLine,
        lightText, 
        headLine, 
        darkText, 
        description, 
        buttonLabel, 
        img, 
        alt, 
        primary, 
        dark, 
        dark2
    
    } ) => {

    return (

        <>

        <InfoContainer lightBg ={lightBg} id={id}>

            <InfoWrapper>

                <InfoRow imgStart={imgStart}>

                <Column1>
                
                <TextWrapper>

                <TopLineShop> {topLine} </TopLineShop>
                <Heading lightText= {lightText} > {headLine} </Heading>

                <Subtitle darkText={darkText} >{description} </Subtitle>

                <BtnWrap>



                <WishListBtn 

                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                primary={primary ? 1 : 0}
                dark={dark ? 1 : 0}
                dark2={dark2 ? 1: 0}

                > 
                                    
                <a href="https://store.steampowered.com/app/1475300/Barbarian/" target = "blank" > Game Page</a>


                </WishListBtn>

                <Button to="discover"
                    
              
                    primary='true'
                    dark='true'

                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}

                    
                    
                    >
                        Dev Logs
                    </Button>

                </BtnWrap>


             
            

                </TextWrapper>


                </Column1>

                <Column2>
                
                    <ImgWrap>
                    
                    <Img src={img} alt={alt} />
                    
                    </ImgWrap>
                
                
                </Column2>

                </InfoRow>

            </InfoWrapper>


        </InfoContainer>

        </>
    )
}

export default InfoSection



export const InfoSectionTwo = (
    {lightBg,
        id, 
        imgStart, 
        topLine,
        lightText, 
        headLine, 
        darkText, 
        description, 
        buttonLabel, 
        img, 
        alt, 
        primary, 
        dark, 
        dark2
    
    } ) => {

    return (

        <>

        <InfoContainer lightBg ={lightBg} id={id}>

            <InfoWrapper>

                <InfoRow imgStart={imgStart}>

                <Column1>
                
                <TextWrapper>

                <TopLine> {topLine} </TopLine>
                <Heading lightText= {lightText} > {headLine} </Heading>

                <Subtitle darkText={darkText} >{description} </Subtitle>

                <BtnWrap>



                <WishListBtn 

                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                primary={primary ? 1 : 0}
                dark={dark ? 1 : 0}
                dark2={dark2 ? 1: 0}

                > 
                                    
                <a href="https://www.youtube.com/c/craftingbenchgames" target = "blank" > Youtube Channel</a>


                </WishListBtn>

              

                </BtnWrap>


             
            

                </TextWrapper>


                </Column1>

                <Column2>
                
                    <ImgWrap>
                    

                    <YoutubeEmbed embedId="cVxan3el42Y?si=DgtYMADfqOpDiqZJ" ></YoutubeEmbed>
                    
                    </ImgWrap>
                
                
                </Column2>

                </InfoRow>

            </InfoWrapper>


        </InfoContainer>

        </>
    )
}


export const InfoSectionMerch = (
    {lightBg,
        id, 
        imgStart, 
        topLine,
        lightText, 
        headLine, 
        darkText, 
        description, 
        buttonLabel, 
        img, 
        alt, 
        primary, 
        dark, 
        dark2
    
    } ) => {

    return (

        <>

        <ShopContainer lightBg ={lightBg} id={id}>

            <InfoWrapper>

                <InfoRow imgStart={imgStart}>

                <Column1>
                
                <TextWrapper>

                <TopLineShop> {topLine} </TopLineShop>
                <Heading lightText= {lightText} > {headLine} </Heading>

                <Subtitle darkText={darkText} >{description} </Subtitle>

                <BtnWrap>




                <ShopBtn 

                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                primary={primary ? 1 : 0}
                dark={dark ? 1 : 0}
                dark2={dark2 ? 1: 0}

                > 
                                    
                <a href="https://craftingbenchgames.shop/" target = "blank" > Shop </a>


                </ShopBtn>


      

                

              

                </BtnWrap>


             
            

                </TextWrapper>


                </Column1>

                <Column2>
                
                    <ImgWrap>
                    
                   <a href='https://craftingbenchgames.shop/' target= "blank"> <Img src={img} alt={alt} /></a>
                    
                    </ImgWrap>
                
                
                </Column2>

                </InfoRow>

            </InfoWrapper>


        </ShopContainer>

        </>
    )
}




import React, {useState} from 'react';
import Navbar from '../Components/NavBar';
import SideBar from '../Components/SideBar';
import HeroSection from '../Components/HeroSection';
import InfoSection from '../Components/InfoSection';
import { InfoSectionTwo, InfoSectionMerch } from '../Components/InfoSection';
import { homeObjOne, homeObjTwo,homeObjThree } from '../Components/InfoSection/Data';
import Footer from '../Components/Footer';


const Home = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    };





    return (
        <>
            <SideBar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <HeroSection/>
            <InfoSectionMerch {...homeObjThree}/>
            <InfoSection {...homeObjOne} />
            <InfoSectionTwo {...homeObjTwo} />

            


            <Footer />
        </>



    )
}

export default Home

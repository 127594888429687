import React from 'react'
import { SidebarContainer, Icon, CloseIcon , SidebarWrapper, SidebarMenu, SidebarLink, SidebarLink2,SidebarLinkShop} from './SideBarElements';

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>

            <Icon onClick={toggle}>

                <CloseIcon />

            </Icon>
            <SidebarWrapper>

            <SidebarMenu>

            <SidebarLink to="about" onClick={toggle}>About</SidebarLink>

            <SidebarLink to="discover" onClick={toggle} > Dev Logs</SidebarLink>

            <SidebarLinkShop> <a href="https://craftingbenchgames.shop/" target = "blank" > Shop </a> </SidebarLinkShop>

            <SidebarLink2> <a href="mailto:contact@craftingbenchgames.com" target = "blank" > Contact</a> </SidebarLink2>

            {/* <SidebarLink to="services" onClick={toggle}> Services </SidebarLink>

            <SidebarLink to="signup" onClick={toggle}> SignUp</SidebarLink> */}

            </SidebarMenu>

            {/* <SideBtnWrap>

              <SidebarRoute to="/signin" onClick={toggle}>Sign in</SidebarRoute>

            </SideBtnWrap> */}

            </SidebarWrapper>


        </SidebarContainer>
    )
}

export default Sidebar

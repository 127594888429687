import React from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { FaMailBulk, FaSteam, FaTwitter, FaYoutube } from 'react-icons/fa'
import { FooterContainer, FooterWrap,SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink } from './FooterElements'
import logo from '../../Images/logo_white.png';


const Footer = () => {




    const toggleHome = () => {

        scroll.scrollToTop();
    };


    return (

        <FooterContainer>

            <FooterWrap>

            {/* <FooterLinksContainer>

             <FooterLinksWrapper>
                <FooterLinkItems>
                    <FooterLinkTitle> About Us </FooterLinkTitle>      
                        <FooterLink to ="/signin"> How it works</FooterLink>
                        <FooterLink to ="/signin"> Testimonials</FooterLink>
                        <FooterLink to ="/signin"> Careers</FooterLink>
                        <FooterLink to ="/signin"> Investors</FooterLink>
                        <FooterLink to ="/signin"> Terms of Service</FooterLink>
                </FooterLinkItems>
                <FooterLinkItems>
                    <FooterLinkTitle> About Us </FooterLinkTitle>      
                        <FooterLink to ="/signin"> How it works</FooterLink>
                        <FooterLink to ="/signin"> Testimonials</FooterLink>
                        <FooterLink to ="/signin"> Careers</FooterLink>
                        <FooterLink to ="/signin"> Investors</FooterLink>
                        <FooterLink to ="/signin"> Terms of Service</FooterLink>
                </FooterLinkItems>
             </FooterLinksWrapper>
             <FooterLinksWrapper>
                <FooterLinkItems>
                    <FooterLinkTitle> About Us </FooterLinkTitle>      
                        <FooterLink to ="/signin"> How it works</FooterLink>
                        <FooterLink to ="/signin"> Testimonials</FooterLink>
                        <FooterLink to ="/signin"> Careers</FooterLink>
                        <FooterLink to ="/signin"> Investors</FooterLink>
                        <FooterLink to ="/signin"> Terms of Service</FooterLink>
                </FooterLinkItems>
                <FooterLinkItems>
                    <FooterLinkTitle> About Us </FooterLinkTitle>      
                        <FooterLink to ="/signin"> How it works</FooterLink>
                        <FooterLink to ="/signin"> Testimonials</FooterLink>
                        <FooterLink to ="/signin"> Careers</FooterLink>
                        <FooterLink to ="/signin"> Investors</FooterLink>
                        <FooterLink to ="/signin"> Terms of Service</FooterLink>
                </FooterLinkItems>
             </FooterLinksWrapper>
            </FooterLinksContainer> */}

            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to ='/' onClick={toggleHome}>


                    <img alt="CraftingBenchGames Logo" src={logo} />
                   


                    </SocialLogo>
                    <WebsiteRights> CraftingBenchGames © {new Date().getFullYear()}  All rights reserved.</WebsiteRights>
                    <SocialIcons>

                   


                        <SocialIconLink href='https://www.youtube.com/channel/UCN7xXv5DC5aG6onexw9vj4g' target="_blank" aria-label="Youtube">
                            <FaYoutube/>
                        </SocialIconLink>

                        <SocialIconLink href='https://twitter.com/CraftBenchGames' target="_blank" aria-label="Twitter">
                            <FaTwitter/>
                        </SocialIconLink>

                        <SocialIconLink href='https://store.steampowered.com/search/?term=CraftingBenchGames' target="_blank" aria-label="Steam">
                            <FaSteam/>
                        </SocialIconLink>

                        <SocialIconLink href='mailto:contact@craftingbenchgames.com' target="_blank" aria-label="Email">
                            <FaMailBulk/>
                        </SocialIconLink>


                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>

            </FooterWrap>
            
        </FooterContainer>
    )
}

export default Footer

import React, {useState} from 'react'
import {Button, WishListBtn} from '../ButtonElements'
import { HeroContainer, HeroBg, HeroContent, HeroH1,HeroP,HeroBtnWrapper,ArrowForward,ArrowRight, HeroLogo} from './HeroElements'
import logo from '../../Images/Barbarian_Logo.png'
import backgroundImg from '../../Images/MainBackground.PNG'

const HeroElements = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {

        setHover(!hover);



    }



    return (
            
        <HeroContainer>
       
       <HeroBg>
            <img alt="Barbarian Game Screenshot" src={backgroundImg} />
        </HeroBg>

            
            <HeroContent>

                {/* Make hero logo here  */}

                <a href="https://store.steampowered.com/app/1475300?utm_source=websitelogo" target="_blank" rel="noopener noreferrer">
                <HeroLogo>
                    <img alt="Barbarian Logo" src={logo} />
                </HeroLogo>
                </a>


                <HeroH1> Now available on Steam!  </HeroH1>


                <HeroP> Add to your wishlist for free! </HeroP>


        
                <WishListBtn primary='true' dark='true'> 
                
                
                <a href="https://store.steampowered.com/app/1475300?utm_source=websitebutton" target = "blank" > Game StorePage</a>
                
                
                </WishListBtn>

         
            

 


                <HeroBtnWrapper>
                    <Button to="about" onMouseEnter={onHover} onMouseLeave={onHover}
                    
                    primary='true'
                    dark='true'

                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}

                    
                    
                    >
                        About Me  {hover ? <ArrowForward /> : <ArrowRight/>}
                    </Button>
                </HeroBtnWrapper>

            </HeroContent>




        </HeroContainer>



    )
}

export default HeroElements
